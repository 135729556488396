import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Slider from "react-slick-slider"
import SliderNextArrow from "../../components/template-partials/slider-components/slider-next-arrow"
import SliderPrevArrow from "../../components/template-partials/slider-components/slider-prev-arrow"


import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"
import QuestionInfocard from "../../components/template-partials/global-components/question-infocard"
import SupportSubmenu from "../../components/template-partials/support/support-submenu"

import { UpNextCard, ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/8.1-headerbg.png"
import headingRound from "../../images/mental-health-nav-image.png"

import {
  faExternalLinkAlt,
  faAngleRight, faDownload, faLink,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import VideoPlayer from "../../components/VideoPlayer"

import Modal from "../../components/modal"

import devon from "../../images/devon-leah.png"
import lori from "../../images/dr-lori.png"
import gavin from "../../images/gavin-wendy.png"
import stephanie from "../../images/stephanie.png"

import thumbRegoaling from "../../images/thumb_how-regoaling-can-reboot-your-life.png"
import thumbImpact from "../../images/thumb_the-impact-on-siblings.png"
import thumbPerspective from "../../images/thumb_how-cancer-changes-your-perspective.png"
import thumbResource from "../../images/thumb-coping-with-childhood.png"


//icon
//import linkIcon from ../../images/icon-link.svg
import Accordion from "../../components/template-partials/global-components/accordion/accordion"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import InlineInfocardIcon from "../../components/template-partials/global-components/inline-infocardicon"

// Youtube Videos 
import { videos } from "../../components/video-data/mental-videos"

const AfterTreatmentWhatsNext = () => {
  const [active, setActive] = useState(1)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: SliderNextArrow,
    prevArrow: SliderPrevArrow,
    responsive: [
      { breakpoint: 1000000, settings: "unslick" },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [videoData, setVideoData] = useState("");
  const [ul, setUl] = useState("");
  const [videoId, setVideoId] = useState('vMEEW2gFrb0?si=SXUknhGZ_HCNhs7C')
  const [videoTitle, setVideoTitle] = useState('Introducing The Panel')

  const rebootVideoUL = ["Devon Still, former NFL player and father of survivor, Leah", "Dr. Lori Wiener, PhD, Head of the Psychosocial Support and Research Program at the National Cancer Institute"]
  const impactOnSiblingsVideoUL = ["Stephanie Ionata, childhood cancer survivor", "Dr. Lori Wiener, PhD, Head of the Psychosocial Support and Research Program at the National Cancer Institute"]
  const cancerPerspectiveVideoUL = ["Wendy Lindberg, bereaved parent and co-founder of The EVAN Foundation", "Devon Still, former NFL player and father of survivor, Leah"]


  const handleModalOpen = (e) => {
    let element = e.target
    if (element.getAttribute('videoId')){
      console.log(element)
      setIsModalOpen(true);
      setVideoId(element.getAttribute('videoId'))
      setTitle(element.getAttribute('title'))
      setDescription(element.getAttribute('description'))
      setVideoData(element.getAttribute('videoData'));
      setUl(eval(element.getAttribute('ul')))
    }
    document.querySelector('html').style.overflow = 'hidden';
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    document.querySelector('html').style.overflow = 'unset';
  };

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  }

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {

      anchor.addEventListener('click', function (e) {
          e.preventDefault();
          //console.log(document.querySelector(this.getAttribute('href')).getAttribute('id'));
          scrollIntoViewWithOffset('#'+document.querySelector(this.getAttribute('href')).getAttribute('id'), 40);

      });
    });
  }, [])

  return (
    <Layout>
      <SEO
        title="Psychological Effects of Childhood Cancer"
        description="Learn about the importance of addressing the psychological effects of living with childhood cancer, and watch a panel discussion with unique perspectives of others."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Mental Health and the Childhood Cancer Journey"
      >
        <h1 className="section__heading section__heading--green h1">
          Mental Health and the Childhood Cancer Journey
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Support`}>
              <SupportSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
              <h2 className="h2 tc-barney jost-med">Mental Health and the Childhood Cancer Journey—A Panel Discussion</h2>
                <p>
                When a child is diagnosed with cancer, it can have an overwhelming effect on the entire family. Mental health is often overlooked in order to focus on the diagnosis and treatment of your child’s cancer. However, the psychological effects of the diagnosis and the challenges that come with living with childhood cancer are as important to address. Having an open conversation about these experiences and how to deal with them is very important.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <p>
            Here you will find a panel discussion featuring Devon Still, former NFL player, and his daughter Leah, a childhood cancer survivor; Dr. Lori Wiener, Head of the Psychosocial Support and Research Program at the National Cancer Institute; Gavin and Wendy Lindberg, bereaved parents and co-founders of The EVAN Foundation; and Stephanie Ionata, a two-time childhood cancer survivor. They will provide their unique perspective on mental health in childhood cancer, including dealing with emotions, learning to be vulnerable, finding the strength to fight, and figuring out overall how to cope with the diagnosis.
            </p>
            <p>
              <span className="tc-viridian jost-med">Jump to a section below to learn more: &nbsp;&nbsp;</span><a href="#meet-the-panel" className="jost-med"><FontAwesomeIcon icon={faLink} />Meet the panel</a>&nbsp;&nbsp;&nbsp;<a href="#resources-guide" className="jost-med"><FontAwesomeIcon icon={faLink} />Coping with Childhood Cancer Guide</a>
            </p>
          </div>
        </div>
        <div className="row row--inner carousel mental-health">
          
          <div className="column carousel-upper-row">
            <VideoPlayer
              className='main-video'
              youtube={true}
              embedId={videoId}
              title={videoTitle}
              style={{
                width: "100%",
                aspectRatio: "16/9",
                margin: "0px ",
                display: "flex",
                alignItems: "flex-start",
              }}
            />
            {videos.map(({embedId,title, description}) => {
              if (embedId === videoId) { 
              return (
                <div className='video-content'>
                  <p>
                    <strong>Video Title: </strong>
                    {title}
                  </p>
                  <p>
                    <strong>Description: </strong>
                    {description}
                  </p>
                </div>)
             }
           })}
          </div>

          <div className="column carousel-bottom-row">
            {videos.map(({ title, embedId, imageSrc }, index) => (
              <>
                <div className={videoId == embedId ? "carousel-video-container active" : "carousel-video-container"}
                onClick={() => {
                  setVideoId(embedId)
                  setVideoTitle(title)
                }} key={embedId}
              >
                <strong className="video-title">
                 {index + 1}: {title}
                </strong>
                <div className="carousel-thumbnail">
                <img
                  alt={title}
                  src={imageSrc}
                  />
                </div>
              </div>
              </>
            ))}
          </div>
        </div>
      </Section>

      <Modal isOpen={isModalOpen} onClose={handleModalClose} title={title} description={description} videoId={videoId} videoData={videoData} ul={ul} />

      <Section className="interior-page" id="meet-the-panel">
        <div className="row row--inner align-middle">
          <div className="columns">
            <h2 className="h2 tc-barney jost-med">
              Meet the panel
            </h2>
            <p>Meet our panel of parents, caregivers, and patients just like you, who have also gone through, and continue to navigate, their own childhood cancer journey. While no two journeys are the same, we hope that the courage and honesty of these families help inspire and comfort you as you navigate your own experience.</p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <Accordion className="">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer><h4 className="tc-viridian jost-med" id="devon-leah">Devon and Leah Still</h4></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns small-12 large-4 large-text-left text-center">
                        <FancyImage
                          url={devon}
                          alt="Devon and Leah Still"
                        />
                      </div>
                      <div className="columns mt-2">
                        <p>
                          Co-founders of the Still Strong Foundation<br/>
                          Devon is a former NFL player and proud father of Leah, a childhood cancer survivor</p>
                        <p>Devon Still, MAPP, MBA, is an author, speaker, podcast host, former NFL athlete, and positive psychology practitioner with specialization in child and adolescent well-being, as well as an assistant instructor at the University of Pennsylvania. But, most importantly, Devon is Leah’s dad. Leah is a high-risk neuroblastoma survivor, and Devon helped her publicly “beat up cancer” and embark on a pediatric cancer-fighting journey that inspired the world.
                        </p>
                        <p>
                          <Link to="/support/braving-neuroblastoma/">
                          Learn more about Devon and Leah Still and their launch of Braving Neuroblastoma
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item isOpen="true">
                <Accordion.Drawer><h4 className="tc-viridian jost-med" id="dr-lori">Dr. Lori Wiener, PhD</h4></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns small-12 large-4 large-text-left text-center">
                        <FancyImage
                          url={lori}
                          alt="Dr. Lori Wiener, PhD"
                        />
                      </div>
                      <div className="columns mt-2">
                        <p>Head of the Psychosocial Support and Research Program at the National Cancer Institute</p>
                        <p>
                          Lori Wiener, PhD, DCSW, LCSW-C, is Co-director of the Behavioral Science Core and Head of the Psychosocial Support and Research Program at the pediatric oncology branch of the National Cancer Institute. As both a clinical and behavioral scientist, Dr. Wiener has dedicated her career to applying what she has learned from her work with seriously ill children and their families to create new, therapeutic communication and educational tools. Dr. Wiener has also dedicated a substantial part of her career to applying knowledge from her clinical experience and psychosocial studies to create innovative resources such as books, workbooks, therapeutic games, and an advance care planning guide for children, adolescents, and young adults. She has been part of the leadership team that has developed the first evidence-based
                          {" "}
                          <a href="https://www.mattiemiracle.com/standards" target="_blank">
                          psychosocial standards of care
                          </a>
                          {" "} for children with cancer and their family members.
                        </p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item isOpen="true">
                <Accordion.Drawer><h4 className="tc-viridian jost-med" id="gavin-wendy">Gavin and Wendy Lindberg</h4></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns small-12 large-4 large-text-left text-center">
                        <FancyImage
                          url={gavin}
                          alt="Gavin and Wendy Lindberg"
                        />
                      </div>
                      <div className="columns mt-2">
                        <p>
                        Bereaved parents and co-founders of <a href="https://www.theevanfoundation.org/" target="_blank">The EVAN Foundation</a>, named after their son Evan Lindberg</p>
                        <p>Gavin Lindberg is President and co-founder of The EVAN Foundation. Established in 2011, The EVAN Foundation is named in memory of Gavin’s only child, who passed away from neuroblastoma in 2010 at the age of 7. The foundation supports childhood cancer patient-wellness programs, neuroblastoma research, and pediatric oncology advocacy and education initiatives. In addition to his work with The EVAN Foundation, Gavin serves as a parent advocate on the St. Baldrick's / Stand Up to Cancer Pediatric Dream Team, the National Cancer Institute's Cancer Moonshot<sup>SM</sup> Pediatric Immunotherapy Discovery and Development Network, the New Approaches to Neuroblastoma Therapy (NANT) Consortium, and the Cancer Grand Challenges award-winning NexTGen Team focused on pediatric solid tumors.</p>
                        <p>Wendy Lindberg is co-founder and Executive Director of The EVAN Foundation. After her son Evan's 4-year battle with neuroblastoma that started when he was 3, she and her husband Gavin established The EVAN Foundation in his memory. Evan was Wendy and Gavin’s only child. With a background in business, Wendy manages foundation operations and organizes and runs fundraising events. The EVAN Foundation supports childhood cancer research, has a Treats & Treasures Carts Program at hospitals across North America, and a College Scholarship Program for Childhood Cancer Survivors.</p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item isOpen="true">
                <Accordion.Drawer><h4 className="tc-viridian jost-med" id="stephanie">Stephanie Ionata</h4></Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns small-12">
                    <div className="row row--inner">
                      <div className="columns small-12 large-4 large-text-left text-center">
                        <FancyImage
                          url={stephanie}
                          alt="Stephanie Ionata"
                        />
                      </div>
                      <div className="columns mt-2">
                        <p>Childhood cancer survivor</p>
                        <p>Stephanie is a relapsed neuroblastoma survivor. Her journey began in 2008 at the age of 5, when she was first diagnosed and treated. Seven years later, as Stephanie was entering middle school, another spot appeared on her neck, and her cancer journey started all over again. After many additional months of treatments and setbacks, Stephanie was declared to have no evidence of disease (NED), and in 2020 entered survivorship. Stephanie was chosen out of 44 recipients from 22 states to receive the Evan Lindberg and Erik Ludwinski College Scholarship. This scholarship is awarded by The EVAN Foundation to 2 survivors of high-risk cancers. Stephanie plans to attend Penn State to pursue her dream of becoming a pediatric oncologist, so that she can continue her quest to help children with cancer.</p>
                      </div>
                    </div>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Section>

      <Section id="resources-guide" style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner">
          <div className="columns small-12 mb-2 md_mb-2 lg_mb-2">
            <h3 className="h2 tc-barney jost-med">
              Resources
            </h3>
          </div>
          <div className="columns small-12">
            <div className="row row--inner">
              <div className="columns small-12 large-4 large-text-left text-center">
                <img
                  src={thumbResource}
                  alt="Resources"
                  className="small-8 large-11"
                />
              </div>
              <div className="columns samll-12 mt-2">
                <h4 className="tc-viridian jost-med">Coping with Childhood Cancer Guide </h4>
                <p>A step-by-step guide to help set a good foundation for you and your child's mental health throughout your journey. This guide takes you from initial diagnosis to after treatment, with considerations to help you cope with childhood cancer and advocate for resources that may be available to you. Use this guide as you navigate the childhood cancer journey.</p>
                <a
                    href="/Mental_Health_Companion_Piece.pdf"
                    data-gtm-event-category="PDF"
                    data-gtm-event-action="Download"
                    data-gtm-event-label="Coping with Childhood Cancer Guide"
                    target="_blank"
                    rel="noreferrer"
                    className="btn--link no-ext"
                  >
                    Download Coping with Childhood Cancer Guide <FontAwesomeIcon icon={faDownload} />
                  </a>
                  <p className="mt-1 md_mt-1 lg_mt-1"><a href="/resources/request-resources/">or request a printed version here</a></p>
              </div>
              <div className="columns small-12 mt-2 md_mt-2 lg_mt-2">
                <p><a href="/resources/downloadable-resources/">To receive additional resources to help you and your family cope with a childhood cancer diagnosis, click here. </a></p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <InteriorPageFooter>
        <UpNextCard topic="Caring for the Caregiver" title="Caring for yourself is as important as caring for your loved ones " lead="Learn about some effective tools for self-care." url="/support/caregiver-support/" />

        <ExploreCard
          title="Coping with a neuroblastoma diagnosis"
          lead="In this section, you will find information to help you cope with diagnosis and prepare for the neuroblastoma journey."
          url="/diagnosis/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default AfterTreatmentWhatsNext
